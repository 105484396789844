
@import ../../assets/stylesheets/mixins
@import ../../assets/stylesheets/colors
@import ../../assets/stylesheets/texts

.activities-header
    @include h4-min-line
.activities-content
    @include h5
    margin-top: 30px
.activities-img
    width: 720px
    height: 480px

@media (max-width:740px)
    .activities-header
        @include h5
    .activities-content
        @include h5-normal
    .activities-img
        width: 360px
        height: 240px