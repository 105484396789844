@import ../../assets/stylesheets/mixins
@import ../../assets/stylesheets/colors
@import ../../assets/stylesheets/texts

.text-input 
    display: flex
    flex-direction: column
    margin-bottom: 16px
    textarea
        height: unset !important
        outline: none
    span
        @include h5
    input,textarea
        @include h7-normal
        width: 100%
        padding: 16px
        border: 1px solid #272727
        box-sizing: border-box
        border-radius: 8px
        flex: none
        height: 56px
        margin: 16px 0px
        background-color: transparent
    .big
        height: 168px

@media (min-width: 799px) and ( max-width:1263px)
  .text-input 
    span
        @include h5-normal

@media ( max-width:799px)
  .text-input 
    input
        height: 48px
    .big
        height: 144px