@import ../../assets/stylesheets/mixins
@import ../../assets/stylesheets/colors
@import ../../assets/stylesheets/texts

.trainings
    height: 100%
    display: flex
    flex-direction: column
    position: relative
    padding: 0 16px
    margin-bottom: 60px !important
    align-items: center
    &-subtitle
        align-self: flex-start
        padding-bottom: 0 !important
    &-second-title
        margin-bottom: 40px
        margin-top: 56px
        align-self: flex-start
    &-content-text
        padding: 0
        min-height: 90px !important
        display: flex
        align-items: flex-end
    button
        margin-top: 40px
        margin-bottom: 120px
        width: 170px
    &-box
        width: 99%
        padding: 16px
        margin-bottom: 40px
        display: flex
        align-items: center
        cursor: pointer
        background: #F2F2F2
        box-shadow: 4px 4px 16px rgba(0, 64, 84, 0.25)
        border-radius: 8px
        min-height: 242px
        &-title
            @include h4-min-line
            display: block
            margin: auto
            width: max-content
            max-width: 100%
            text-align: center
        &-content-text
            @include h5-normal
        &-left
            width: 40%
            height: 100%
            justify-content: space-between
            display: flex
            flex-direction: column
            align-items: center
            img
                width: 100%
                max-width: 316px
                height: 210px
                border-radius: 8px
        &-right
            width: 60%
            align-items: center
            justify-content: center
            vertical-align: middle

@media (max-width: 1263px)
    .trainings
        &-second-title
            margin-top: 0 !important
        &-box-right
            margin-left: 16px
        button
            margin-bottom: 80px
        &-content-text.description-text
            @include h6-400

@media (max-width:799px)
    .hero-wrapper-text
        white-space: pre-wrap
    .trainings-box
        width: 100%
        flex-direction: column
        &-left
            width: 100%
        &-right
            width: 100%
        &-title
            margin-top: 40px
            padding-top: 0
