@import ../../assets/stylesheets/_texts

.modal
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: #a8a8a8a8
    z-index: 99
    .centered
        justify-content:  center
    &-wrapper
        position: absolute
        top: 40%
        display: flex
        justify-content: center
        width: 100%
    &-content
        position: relative
        max-width: 640px
        width: 80%
        height: 184px
        background: #F2F2F2
        box-shadow: 4px 4px 16px rgba(0, 64, 84, 0.25)
        border-radius: 8px
        padding: 24px
        text-align: center
        align-self: center
        display: flex
        flex-direction: column
        justify-content: space-between
        span
            @include h5-normal
            display: block
@media screen and ( max-width: 800px )
    .modal
        &-content
            padding: 16px

    