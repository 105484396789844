@import ../../assets/stylesheets/mixins
@import ../../assets/stylesheets/colors
@import ../../assets/stylesheets/texts

.cooperation
    display: flex
    flex-direction: column
    position: relative
    max-width: 1263px
    padding: 0 16px
    &-subtitle
        margin-top: 0
        margin-bottom: 48px !important
    &-second-title
        margin: 48px 0
    .last-text
        margin-bottom: 80px
    .list
        display: flex
        align-items: center
        justify-content: center
        cursor: pointer
        margin-top: 136px 
        margin-bottom: 96px
        .help-section-content
            padding: 8px
            margin-right: 16px !important
            margin-left: 0
        &-item
            margin: 8px
            width: 33%
            height: 276px
            border-radius: 8px
            text-align: center
            justify-content: space-between
            &:hover
                box-shadow:4px 4px 16px rgba(39, 39, 39, 0.25)
            &[data-active="true"]
                box-shadow:4px 4px 16px rgba(39, 39, 39, 0.25)  
            span
                display: block
                padding-top: 0
                min-height: unset
        img
            width: 72%
            border: 1px solid #272727
            border-radius: 8px
            object-fit: cover
            height: 200px
            margin-bottom: 10px
            min-width: unset
        .home-help-header
            color: $common-black !important
    .desc
        height: 0
        width: 100%
        &-item
            display: flex
            flex-direction: column
            width: 100%
        &[data-visible="true"]
            height: max-content


@media  ( max-width:1263px)
    .cooperation
        &-second-title.cooperation-description
            @include h4-min-line
        .home-help-header
            @include h4-min-line
        .list
            display: flex
            margin-top: 56px
            white-space: pre
            margin-bottom: 56px
            &-item
                max-width: 310px
                height: 230px
            img
                height: 142px


@media (max-width: 798px )
    .cooperation
        .list
            margin-top: 56px
            flex-direction: column
            &-item
                width: 90%
                display: flex
                max-width: 400px
                margin-bottom: 32px
                margin-top: 0
                &.help-section-content
                    margin-right:  0 !important
            img
                width: 80%
                max-width: 200px
                min-width: unset
            span
                width: 100%
                white-space: initial
                align-self: center
    