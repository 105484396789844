@import ../../assets/stylesheets/mixins
@import ../../assets/stylesheets/colors
@import ../../assets/stylesheets/texts

.check-header
    @include h5-normal
    margin-bottom: 16px
.container
    display: flex
    flex-direction: row   
    margin-bottom: 32px 
    input
        height: 24px
        width: 24px
        vertical-align: middle
        border-radius: 8px !important
    span
        @include h5-normal
        vertical-align: middle
        margin-right: 32px
        margin-left: 16px

