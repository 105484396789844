@import ../../assets/stylesheets/mixins
@import ../../assets/stylesheets/colors
@import ../../assets/stylesheets/texts

.financing
    height:100%   
    display: flex
    min-height: 100px
    flex-direction: column
    padding: 16px
    position: relative
    margin-top: 65px !important
    max-width: 1264px
    margin: auto
    a
        text-decoration: underline
        cursor: pointer
    &-second-title
        display: block
    &-content-text
        font-weight: 400 !important
        margin-bottom: 10px
    .top-box-left, .top-box-right
        .financing-content-text
            &:last-of-type
                margin-bottom: 0
    ul
        margin-top: 0
        margin-bottom: 24px
    button
        width: 150px
        margin: 24px
    .top-box
        margin-bottom: 24px
        display: flex
        width: max-content
        border: 1px solid #000000
        overflow: hidden
        padding: 5px
        &-left , &-right
            display: flex
            flex-direction: column
            min-width: max-content
            span
                white-space: pre
        &-left
            padding-right: 6px

.support-section
    display: flex
    flex-direction: column
    margin: 120px 0
    .financing-second-title
        margin-bottom: 24px
        &:last-of-type
            margin-top: 24px

.footer-button
    align-self: center

@media ( max-width:1263px)
    .financing
        margin-top: 40px !important
        padding-top: 0 !important
        .support-section
            margin: 80px 0
        &-second-title.financing-sub-header
            @include h5-500 
    
@media screen and ( max-width: 800px )
    .financing
        &-content-text.table
            max-width: 186px
            white-space: initial !important
        
@media ( max-width:414px)
    .financing
        top: 16px
        .top-box
            width: 100% 
            margin-bottom: 30px

    
