@font-face
    font-family: Ubuntu
    src: local(Ubuntu-Regular), url('./Ubuntu-Regular.ttf') format("truetype")
    font-weight: normal
    font-style: normal
    font-stretch: normal

@font-face
    font-family: Ubuntu
    src: local(Ubuntu-Medium), url('./Ubuntu-Medium.ttf') format("truetype")
    font-weight: 500
    font-style: normal
    font-stretch: normal

@font-face
    font-family: Ubuntu
    src: local(Ubuntu-Bold), url('./Ubuntu-Bold.ttf') format("truetype")
    font-weight: bold
    font-style: normal
    font-stretch: normal


