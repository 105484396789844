@import ../../assets/stylesheets/mixins
@import ../../assets/stylesheets/colors
@import ../../assets/stylesheets/_texts

.cookies, .use, .privacy-policy, .terms-of-use
    height:100%   
    display: flex
    flex-direction: column
    position: relative
    max-width: 1263px
    padding: 16px
    margin-bottom: 80px !important
    table 
        border-collapse: collapse
    th
        padding: 8px !important
    tr
        text-align: left
        td
            border-left: none
            border-right: none
            padding: 8px !important
        &:last-of-type
            td
                border: none
    table, td
        border: 1px solid black
    a
        text-decoration: none
    p
        @include h4-min-line
    &-subheader
        @include h5-500
        margin: 24px 0
        font-weight: 500 !important
    .list-header, .bottom-text
        margin:  24px 0
    ul
        margin-top: 0
    &-content
        display: flex
        flex-direction: column
    &-header
        margin-bottom: 0
    

@media ( max-width:1263px)
    .cookies.wrapper, .privacy-policy.wrapper, .terms-of-use.wrapper
        margin-top: 40px !important
        margin-bottom: 40px !important

