@import ../../assets/stylesheets/texts

.hero-wrapper
    display: flex
    width: 100%
    height: 560px
    position: relative
    overflow: hidden
    
    &-image
        min-width: 100%
        max-height: 100%
        position: absolute
        object-fit: cover
    &-text
        @include hero
        z-index: 9
        width: 100%
        max-width: 1264px
        padding: 16px
        margin: auto

@media (max-width: 1263px)
    .hero-wrapper
        height: calc(max(400px, 40vw))
    .hero-wrapper-text
        left: 10px !important
        
        
@media (max-width: 800px)
    .hero-wrapper
        height: calc(max(198px, 38vw))
        &-text
            @include hero-mobile
        &-image
            margin-left: -50px
            min-width: 116%


