
$common-white: #fff
$common-black: #000
$common-green: #98C04D
$common-orange: #EA652C
$common-blue: #1492BA

$text-color: #231F20
$text-grey: #231F20
$footer-background: #FFFFFF

$colorTop: #1492BA
$colorBottom: #1492BA
$colorMid: #1492BA
$header: #272727
$home-background: linear-gradient(168deg, #ebf5f9, rgb(154 229 253 / 63%) 0%, #e2f0ef, rgba(226, 255, 168, 0.3) 65%, rgba(234, 101, 44, 0.3) 110%)
