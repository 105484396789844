@import ../../assets/stylesheets/mixins
@import ../../assets/stylesheets/colors
@import ../../assets/stylesheets/texts

.footer
  width: 100%
  background-color: $footer-background
  display: flex
  height: 226px
  flex-direction: column
  overflow: hidden
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2)
  padding: 16px 
  position: relative
  &-content-text
    @include h6
  a
    text-decoration: none
  img
    margin-right: 8px
  .flag
    min-width: 135px
  .ktzLogo
    min-width: 75px
    max-width: 75px
  &-section
    display: flex
    align-content: center
    align-items: center
    align-self: center
    justify-content: center
    max-width: 1264px
    .footer-content-text
      display: none 
  .ecoso
    width: 293px
  &-component
    margin-bottom: 16px
    align-self: center
    align-items: center
    justify-content: center
    display: block
    align-items: center
    div
      display: flex
      align-items: center
      justify-content: center
      a
        align-items: center
        display: flex
      img
        width: 24px
      &:first-of-type
        margin-bottom: 28px
        
        a
          margin-right: 12px
          
    a
      @include h6-400
      margin-right: 32px
      font-size: 14px
      &:last-of-type
        margin-right: 0
  
@media (min-width: 1263px)
  .footer
    &-section
      .footer-content-text
        display: flex
        margin-right: 16px
    &-content-text
      width: 100%
      margin: 0
      align-self: center
      display: none
      justify-content: center
    &-component
      margin-top: 24px 
      

@media  (max-width:1263px) 
 .footer
    height: 322px
    &-content-text
      margin: 24px 0px
      align-self: center

@media  (max-width:799px)
  .footer
    min-height: 308px
    height: unset
    padding: 16px
    .flag
      width: 90px
      min-width: 90px
    .ktzLogo
      min-width: 50px
    .ktzLogo, .flag
      max-height: 60px
    .ecoso
      width: 200px
      height: 37px
      align-items: center
    .footer-component
      margin-top: 0px
      margin-bottom: 0px
      max-height: 150px
      flex-wrap: wrap
@media  (max-width:414px)
  .footer
    height: unset 
    min-height: 550px 
    &-component
      flex-wrap: wrap
      height: unset
      div
        flex-direction: column
        height: unset
        a
          margin: 0
          margin-top: 12px
        &:first-of-type
          margin-bottom: 12px
    &-section
      img
        min-width: unset !important
        &:first-of-type
          width: 25% !important
        &:nth-of-type(2)
          width: 17% !important
        &:last-of-type
          width: 50% !important
          