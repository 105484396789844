@import ../../assets/stylesheets/mixins
@import ../../assets/stylesheets/colors
@import ../../assets/stylesheets/texts

.contact-form
   height: 100%
   display: flex
   min-height: 100px
   max-height: 100%
   flex-direction: column
   position: relative
   margin: auto
   max-width: 664px !important
   margin-bottom: 80px !important
   button
      width: 220px
      margin-top: 30px
   &-title
      margin-bottom: 40px
   &-box
      display: flex
      justify-content: space-between
      &-left , &-right
         width: 48%
         display: inline-block
         max-width: 280px
.selectbox
   padding: 16px
   background-color: transparent
   outline: none
   border: none
   margin-bottom: 24px
   width: 100%
   -webkit-appearance: none
   -moz-appearance: none
   padding-right: 40px
   text-indent: 1px
   &-wrapper
      display: flex
      flex-direction: column
      margin-bottom: 40px
      span
         margin-bottom: 16px
   &-container
      border-radius: 8px
      border: 1px solid #272727
      height: 48px
      overflow: hidden
      position: relative
      &::after
         width: 14px
         height: 14px
         border-left: 1px solid #1d1d1d
         border-bottom: 1px solid #1d1d1d
         position: absolute
         transform: rotate(-45deg)
         top: 30%
         right: 12px
         content: ""
         z-index: 98
         pointer-events: none

@media ( max-width:1263px)
   .contact-form.form
      margin-top: 40px !important
      padding: 16px
      padding-top: 0
      &-title
         margin-bottom: 40px !important

@media ( max-width:799px)
   .contact-form
      &-title.form-title
         @include h4-min-line
      &-box
         flex-direction: column
         &-left, &-right
            width: 100%
            max-width: unset
