@import ./mixins
@import ./colors

@mixin h1-500
  +font($header, 48px, 500, 52px,Ubuntu, normal)
@mixin carousel-header
  +font($header, 40px, 500, 48px, Ubuntu,normal )
@mixin h2-700
  +font($header, 36px, 700, 40px,Ubuntu, normal)
@mixin h4-min-line
  +font($header, 24px, 500, 32px,Ubuntu)
@mixin h4-500
  +font($header, 24px, 500, 36px,Ubuntu)
@mixin h4-500
  +font($header, 24px, 500, 36px,Ubuntu)
@mixin h5-normal
  +font($header, 18px, normal, 24px,Ubuntu)
@mixin h5
  +font($header, 18px, 400, 24px,Ubuntu)
@mixin h5-500($color: $header)
  +font($color, 18px, 500, 24px,Ubuntu)
@mixin h5-700
  +font($header, 18px, 700, 24px,Ubuntu, normal)
@mixin h5-bold
  +font($header, 18px, bold, 24px,Ubuntu)
@mixin h6
  +font($header, 16px, normal, 24px,Ubuntu)
@mixin button
  +font($common-white, 16px, 500, 24px,Ubuntu, normal)
@mixin h6-400($color: $header)
  +font($color, 16px, 400, 24px,Ubuntu, normal)
@mixin h7-normal
  +font($header, 14px, normal, 16px,Ubuntu)
@mixin hero
  +font($header, 72px, 500, 72px,Ubuntu, normal)
@mixin hero-mobile
  +font(#F2F2F2,32px, 700, 32px,Ubuntu, normal)
@mixin header
  +font($header, 18px, 500, 24px, Ubuntu, normal)
