
@import ../../assets/stylesheets/_texts

.carousel-wrapper
    display: flex
    max-height: 560px
    margin-bottom: 24px
    width: 100%
    max-width: 1264px
    .link-button
        align-self: flex-start
        padding: 8px 60px
    .carousel
        &-image
            min-width: 54%
    .slick-next, .slick-prev
        top: 50%
        width: 26px !important
        height:48px
        z-index: 9
        -webkit-filter: drop-shadow(-1px 0px 1px #1d1d1d)
    .slick-next
        right: 16px !important
    .slick-prev
        left: 16px !important
        top: 45%
        transform: rotate(180deg)
    .slick-track
        min-width: max-content
    .slick-slider
        width: 100%
        margin: auto
    .slick-list
        box-shadow: 4px 4px 16px rgba(0, 64, 84, 0.25)
        border-radius: 8px !important
        overflow: hidden 
        .carousel-card
            display: flex !important
            background: #F2F2F2
            border-radius: 8px
            overflow: hidden
            margin-right: 5px
            min-height: 560px
            position: relative
            .carousel
                &-background-image
                    position: absolute
                    width: 100%
                    object-fit: fill
                    height: 100%
                &-content
                    z-index: 10
                    display: flex
                    flex-direction: column
                    align-items: flex-start
                    justify-content: flex-end
                    padding:  40px 48px
                    h4
                        @include carousel-header
                        margin: 24px 0

@media screen and ( max-width: 1263px )
    .carousel-wrapper
        .slick-list .carousel-card
            min-height: 340px !important
            .carousel
                &-image
                    min-width: 52%
                &-content
                    padding: 24px 60px
                    h4
                        @include h5-500


@media screen and ( max-width: 799px )
    .carousel-wrapper
        max-width: 600px
        .slick-prev
            left: 10px !important
            top: 11%
        .slick-next
            right: 10px !important
            top: 17%
        .slick-list 
            .carousel-card
                flex-direction: column
                min-height: 197px !important
                img
                    width: 100%
                    max-height: 180px
                    position: relative !important
                    height:25%
                    min-height: 169px
                .carousel-content
                    padding: 16px
                    background-color: #272727
                    justify-content: flex-start
                    min-height: 145px
                    justify-content: space-between
                    a
                        margin: 0 auto
                    h4
                        margin-top: 0 !important
                        color: white !important
                        
@media screen and ( min-width: 800px )
    .carousel-card
        .carousel-content
            .hide
                display: none