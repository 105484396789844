@import ../../assets/stylesheets/mixins
@import ../../assets/stylesheets/colors
@import ../../assets/stylesheets/texts

*
  box-sizing: border-box

body
  margin: 0px
  background: $home-background
 
.header
  width: 100%
  height: 152px
  background-color: $footer-background !important
  display: flex
  align-items: center
  align-self: center
  justify-content: center
  overflow: hidden
  padding: 0px
  z-index: 1
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2)
  padding: 16px
  .username
    @include header
    cursor: pointer
  .navbar
    display: flex
    flex-direction: column
    justify-content: space-around
    width: 70%
    height: 100%
    .button-wrapper
      display: flex
      justify-content: flex-end
  .burger-menu
    width: 32px
    height: 28px
    cursor: pointer
    display: none
    flex-direction: column
    justify-content: space-between
    .line
      width: 100%
      height: 6px
      border-radius: 8px
      background-color: $common-green
  .burger-content
      position: absolute
      display: flex
      overflow: hidden
      flex-direction: column
      background-color: $common-white !important
      top: 119px
      transition: height .1s linear
      right: 0px
      text-align: right
      align-items: center
      box-shadow: 0px 2px 2px rgba(39, 39, 39, 0.5)
      z-index: 10
      padding: 16px
      z-index: 10
      >a:last-of-type
        border: none !important
        padding-bottom: 0 
        margin-bottom: 0 !important
  &-logo
    cursor: pointer
    display: flex
    align-items: center
    img
      width: 196px
      max-height: 132px
  .nav-item
    margin: 6px
    min-width: max-content
    margin-bottom: 0
    padding-bottom: 0
    &:hover
      color: $common-blue !important
    &.active
      color: $common-blue !important
    &:first-of-type
      margin-left: 0
      padding-left: 0
  a
    @include header
    padding: 0.7%
    text-decoration: none
  .header-wrapper
    width: 100%
    max-width: 1260px
    height: 100%
    align-items: center
    justify-content: space-between
    display: flex
    &-bottom
      display: flex
      justify-content: center
      
    .link-content
      display: flex
      width: 100%
      justify-content: flex-end
      min-width: max-content
      align-items: flex-end
    .burger-content
      .nav-item
        border-bottom: 1px solid #272727
        padding-bottom: 16px
        margin-bottom: 16px
        align-self: flex-end
  
@media  ( max-width:1045px)
  .header
    justify-content: space-between
    height: 120px
    &-logo
      img
        width: 131px
    .burger-menu
      display: flex
    &-wrapper-bottom, &-wrapper button
      display: none
    .link-content
      flex-direction: column
      text-align: center

@media  ( max-width:799px)
  .header
    height: 80px
    justify-content: space-between
    &-logo
      img
        width: 100px
        height: 61px
    .burger-content
      top: 79px