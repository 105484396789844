@import ../../assets/stylesheets/mixins
@import ../../assets/stylesheets/colors
@import ../../assets/stylesheets/texts

input[type=number] 
    -moz-appearance: textfield 

.consultancy
    height:100%   
    display: flex
    min-height: 100px
    flex-direction: column
    padding: 16px
    position: relative
    .non-clickable
        pointer-events: none
    &-content-text
        ul
            margin-top: 0
    &-bottom
        height: 50px
        margin: 50px
    .link-button
        @include button
        margin: 24px
    a
        color: $common-black
        text-decoration: none
    &-help
        margin: 120px 0px
        text-align: center  
        margin-bottom: 50px
        img
            object-fit: contain !important
            min-width: unset
        .help-section-content
            padding: 16px 0
            .home-help-header
                @include h4-500
                min-height: 68px
                padding-top: 12px !important

@media  ( max-width:1263px)
    .consultancy
        margin-top: 36px !important
        &-help
            margin: 80px 0
        .help-section-content
            padding-right: 0  
            margin-right: 16px
            width: 23.5%
            .home-help-header
                @include h4-500
            .home-text-two
                font-size: 18px 
                padding-top: 16px

@media (max-width: 799px)
     .consultancy
        margin-top: 24px !important
        .help-section-content
            min-width: 100%
            max-width: 450px
            margin-right: 0
            img
                width: 65% !important
                min-width: unset
                max-height: 150px !important
            .home-help-header
                padding: 0 !important
                min-height: unset
        button
            margin-bottom: 40px
