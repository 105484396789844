@import ../../assets/stylesheets/mixins
@import ../../assets/stylesheets/colors
@import ../../assets/stylesheets/texts

.about-us
    height:100%   
    display: flex
    min-height: 100px
    flex-direction: column
    position: relative
    padding: 16px
    a
        text-decoration: none
    &-title.title
      margin-bottom: 48px !important
      @include h4-min-line
.section-wrapper
  align-self: center
  text-align: center
  display: flex
  width: 100%
  flex-direction: column
  margin: 112px 0
.about-us-section
  display: flex
  width: 100%
  padding: 0px
  justify-content: center
  &-content
    width: 288px
    height: 216px
    text-align: center
    display: flex
    flex-direction: column
    margin: 8px
    border-radius: 8px
    padding-bottom: 0
    &:first-of-type
      margin-left: 0
    &:last-of-type
      margin-right: 0
    span
      @include h4-min-line
      color: $common-black !important
      margin: auto
    img
      width: 200px
      height: 155px
      align-self: center
      margin-bottom: 0
      object-fit: cover
      min-width: 120%
  
@media (max-width: 1263px)
  .about-us.wrapper
    margin-top: 0 !important
  .about-us-section
      flex-wrap: wrap
      &-content
        width: 22%
        height: 176px
        text-align: center
        display: flex
        flex-direction: column
        span
          @include h5-500
      img
        width: 90%
        max-width: 180px
        height: 128px
        align-self: center

@media (max-width:799px)
    .about-us
        width: 100%
        top: 24px
    .about-us-section
      flex-direction: column
      align-items: center
      &-content
        height: 327px
        text-align: center
        display: flex
        flex-direction: column
        margin: 0
        margin-bottom: 32px
        min-width: 100%
        max-width: 98%
        img
          max-width: 250px
          height: 80%

@media (max-width:414px)
    .about-us
        top:16px
    .about-us-section
      flex-wrap: wrap
      width: 100%
      align-self: center 
      justify-content: center



    
