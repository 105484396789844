@import ../../assets/stylesheets/mixins
@import ../../assets/stylesheets/colors
@import ../../assets/stylesheets/texts

.home
  height: 100%
  display: flex
  min-height: 100px
  align-items: center
  flex-direction: column
  padding: 0
  margin-top: 80px !important
  margin-bottom: 96px !important
  margin: auto
  max-width: 1260px
  .entry
    display: block
    min-height: max-content
    align-items: center
    width: 91%
    text-align: center
    margin-top: 100px
    span
      width: 100%
      display: block
  iframe
    border: 0
    background-color: transparent
    max-width: 1024px
    margin-bottom: 24px
    max-height: 576px
    height: calc(min(576px, 54vw))
    border-radius: 16px
    margin-top: 24px
    width: 100%
  button
    height: 48px
    width: 220px
  &-header
    @include h4-min-line
    text-align: center
    letter-spacing: normal
  &-contact
    @include h1-500
    &-text
      @include h5-normal
      margin: 24px 0
  &-content-text
    text-align: center
    margin-top: 24px
  &-help-header
    @include h4-min-line
    text-align: center
    min-height: 74px
    padding-top: 16px
  &-text-two
    @include h5
    text-align: start

.help
  margin-top: 120px
  text-align: center
  &-top-header
    @include h1-500
    text-align: center
.help-section
  display: flex
  width: 100%
  margin-top: 24px
  &-content
    width: 24%
    max-width: 280px !important
    display: flex
    flex-direction: column
    overflow: hidden
    margin: 0
    margin-right: 37px !important
    background-color: #F2F2F2
    margin-bottom: 16px
    box-shadow: 4px 4px 16px rgba(0, 64, 84, 0.25)
    border-radius: 8px
    padding: 0 0 16px
    a,p
      text-decoration: none !important
    .bottom-wrapper
      padding: 0 16px
      padding-bottom: 8px
      span
        display: block
    &:last-of-type
      padding-right: 0
      margin-right: 0 !important
    &:first-of-type
      .home-help-header
        padding-top: 0
    img
      height: 200px
      object-fit: cover
      align-self: center
      margin-bottom: 20px
      min-width: 115%

.section
  align-self: center
  text-align: center
  display: flex
  flex-direction: column
  padding-top: 110px
@media screen and ( min-width: 1263px )
  .home
    padding: 16px
  
@media  (max-width:1263px)
  .home
    padding: 16px
    &-help-header
      min-height: 65px
    &-contact
      @include h4-min-line
    &-header
      @include h5-500
      text-align: center
      letter-spacing: normal
    &-content-text
      font-weight: 400
    &-help-header
      @include h5-500
    button
      @include button
      font-weight: normal
    &-contact-text
      @include h5-normal
    &-text-two
      @include h6-400
    video
      margin-top: 24px
      margin-bottom: 0
  .entry
    flex-direction: column
    width: 720px
    img
      width: 640px
      height: 360px
      padding: 24px
  .help
    margin-top: 80px
    &-top-header
      @include h4-min-line
  .help-section
    margin: auto
    padding-top: 32px
    justify-content: center
    &-content
      width: 25%
      padding: 0
      margin-right: 16px !important
      text-align: center
      max-height: 800px
      &:last-of-type
        margin-right: 0 !important
      img
        width: 100%
        padding-top: 0
        align-self: center
        margin-bottom: 20px
        height: 128px
  .section
    padding-top: 96px
@media (max-width:799px)
  .home
    align-items: center
    margin-bottom: 40px !important
    .bottom-wrapper
      padding-bottom: 16px
    &-content-text
      margin-top: 0px
    &-help-header
      padding: 16px 0
      min-height: unset
    &-text-two
      padding-bottom: 0px
  .entry
    flex-direction: column
    img
      width: 320px
      height: 180px
      padding: 10px
  .help
    margin-top: 48px
    text-align: center
    width: 100%
    &-top-header
      @include h4-min-line
  .help-section
    flex-direction: column
    align-items: center
    text-align: center
    &-content
      min-width: 100%
      max-width: 320px
      text-align: center
      display: flex
      max-width: unset
      flex-direction: column
      margin: 0
      margin-bottom: 32px
      margin-right: 0 !important
      max-height: max-content
      &:last-of-type
        img
            object-fit: cover
            width: 116%
            max-height: 360px
           
      img
        width: 100%
        padding-top: 0
        align-self: center
        object-fit: cover
        min-height: 270px !important

  .section
    padding: 24px 0px
    width: 100%
