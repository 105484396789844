@import ./colors
@import ./texts

button, .link-button
   @include button
   padding: 8px 21px
   outline: none
   cursor: pointer
   margin: 0 8px
   border: none
   height: 40px
   min-width: 80px
   max-width: 220px
   border-radius: 8px
   background-color: $common-green
   align-items: center
   align-self: center
   box-shadow: 2px 2px 8px rgba(0, 64, 84, 0.25)
   text-decoration: none
   &:hover
      box-shadow: 8px 8px 16px rgba(0, 64, 84, 0.25)
      
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
   -webkit-appearance: none
   margin: 0
input[type=number]
   -moz-appearance: textfield

.financing, .consultancy, .about-us, .cooperation,.trainings, .home,.contact-form, .cookies, .privacy-policy, .terms-of-use
   margin-top: 80px !important
   max-width: 1264px
   margin: auto
   &-content-text, li, .about-us-content
      @include h5
   &-second-title
      @include h4-min-line
   &-subtitle, &-title
      @include h1-500
      margin-bottom: 24px

@media (max-width: 1263px)
   .financing, .consultancy,.about-us , .cooperation, .trainings, .home
      margin-top: 40px !important
      &-content-text , li, .about-us-content
         @include h5
      &-subtitle
         @include h4-min-line
      &-second-title
         @include h5-500

@media (max-width: 800px)
   .financing,  .trainings
      margin-top: 40px !important
      &-content-text , li
         @include h6
