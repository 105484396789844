@import ../../assets/stylesheets/mixins
@import ../../assets/stylesheets/colors
@import ../../assets/stylesheets/texts

.publication
  .help-top-header
    display: block
    margin: 100px 0
  .help-section
    display: flex
    flex-wrap: wrap
    justify-content: center
    gap: 20px
    margin: 50px 0
    .home-help-header 
      display: none
    .home-text-two
      text-align: center

